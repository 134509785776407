<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <PageDeveloperUploadExcelSubscriptionHistory/>
        <ComponentDeveloperFixYearSubscription/>
        <!-- <ComponentDeveloperAddSubscriptionHistory 
        :id=companyId></ComponentDeveloperAddSubscriptionHistory>    -->
        <ComponentDeveloperSubscriptionHistoryList/>
        <ComponentYearSubscription/>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import PageDeveloperUploadExcelSubscriptionHistory from './PageDeveloperUploadExcelSubscriptionHistory.vue';
import ComponentDeveloperSubscriptionHistoryList from '../../components/developer/clean/ComponentDeveloperSubscriptionHistoryList.vue';
import ComponentDeveloperFixYearSubscription from '../../components/developer/clean/ComponentDeveloperFixYearSubscription.vue';
import ComponentYearSubscription from '../../components/subscription/ComponentYearSubscription.vue';
// import ComponentDeveloperAddSubscriptionHistory from '../../components/developer/clean/ComponentDeveloperAddSubscriptionHistory.vue';
export default {
    components:{
    PageDeveloperUploadExcelSubscriptionHistory,
    ComponentDeveloperSubscriptionHistoryList,
    ComponentDeveloperFixYearSubscription,
    ComponentYearSubscription
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        year:1,
        data:null,
        dataHeader:[
        ],
        companyId:99,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
          
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>