<template>
    <v-container>
        <ASuccessFour :api="api"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">

                <v-btn
                    color="primary"
                    @click="validateInput()">
                    Update Subscription year
                </v-btn>

                <!-- <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col> -->
                <!-- <v-card class="pa-3" elevation-1> -->
                    <!--BOC : Create a form here-->


                    <!--EOC-->
                <!-- </v-card> -->
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../common/AConfirmation.vue';
import ASuccessFour from '../../common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="fixSubscriptionYear") {
                this.api.isSuccesful = true;
                this.api.success = "Successfully update the year of subscription";
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    //
    },
    methods: {
        fetch(){

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/subscription_history/fix_subscription_year";
            this.$api.fetch(tempApi);
        },

        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>