<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-data-table
            v-if="!api.isLoading"
            :items="data"
            :search="search"
            :headers="dataHeader">
            <template
                v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:item.subscription_start_date="{item}">
                <span
                    v-if="item.subscription_start_date!=null">
                    {{ convertTimeZone(item.subscription_start_date) }}
                </span>
            </template>

            <template
                v-slot:item.subscription_end_date="{item}">
                <span
                    v-if="item.subscription_end_date!=null">
                    {{ convertTimeZone(item.subscription_end_date) }}
                </span>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Subscription Year
                                </v-toolbar-title>
                            </v-row>
                            <v-row>
                                <v-autocomplete
                                    :items="yearOptions"
                                    v-model="year"
                                    @change="fetch()"
                                    dense 
                                    outlined
                                    label="Year">

                                </v-autocomplete>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-row>
                                                <v-col>
                                                    <ComponentDateModal
                                                        @updateDateData="(data)=>{
                                                            filter.subscriptionStartDate = data;
                                                        }"
                                                        :data="filter.subscriptionStartDate"
                                                        label="Subscription start date"/>

                                                </v-col>
                                                <v-col>
                                                    <ComponentDateModal 
                                                        @updateDateData="(data)=>{
                                                            filter.subscriptionEndDate = data;
                                                        }"
                                                        :data="filter.subscriptionEndDate"
                                                        label="Subscription end date"/>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col>
                                            <v-row>
                                                <v-col>
                                                    <ComponentDateModal
                                                        :data="filter.expiredStartDate"
                                                        @updateDateData="(data)=>{
                                                            filter.expiredStartDate = data;
                                                        }"
                                                        label="Expired start date"/>
                                                </v-col>
                                                <v-col>
                                                    <ComponentDateModal
                                                        :data="filter.expiredEndDate"
                                                        label="Expired end date"
                                                        @updateDateData="(data)=>{
                                                            filter.expiredEndDate = data;
                                                        }"/>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                               </v-row>
                            <v-row>
                                <v-col>
                                    <div
                                        class="d-flex justify-end">
                                        <v-btn
                                            @click="fetch()"
                                            color="primary">
                                            Filter
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense
                                    outlined 
                                    label="Search">

                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import ComponentDateModal from '../../components/date/ComponentDateModal.vue';
export default {
    components:{
    ASuccessWithoutReload,
    ComponentDateModal,
},
    computed: mapState({
    //
}),
    props:[
    ],
    data: () => ({
        search:"",
        data:null,
        year:1,
        yearOptions:["All",1,2,3,4],
        dataHeader:[
            {
                text:'#',
                value:'Index',
            },
            {
                text:'Company id',
                value:'company_id',  
            },
            {
                text:'Company name',
                value:'name',
            },
         
            {
                text:'Vision key',
                value:'vision_key',
            },
            {
                text:'Year',
                value:'year',
            },
            {
                text:'status',
                value:'status',
            },
            {
                text:'Subscription start date',
                value:'subscription_start_date',
            },
            {
                text:'Subscription end date',
                value:'subscription_end_date',
            },
            {
                text:'Year of subscription',
                value:'subscription_year',
            }
        ],
        filter:{
            subscriptionStartDate:null,
            subscriptionEndDate:null,
            expiredStartDate:null,
            expiredEndDate:null,
        },
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class === "getSubscriptionHistoryYear") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchYearOfSubscriptionHistory());
        },

       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchYearOfSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = 'GET';
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription_history/year/"+this.year+"?subscription_start_date="+this.filter.subscriptionStartDate+"&subscription_end_date="+this.filter.subscriptionEndDate+"&expired_start_date="+this.filter.expiredStartDate+"&expired_end_date="+this.filter.expiredEndDate;

            return tempApi;
        },
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        convertTimeZone(time){
            return this.$moment(time).format('LL');
        }
    }
}
</script>